<template>
  <div></div>
<!--  <div class="transfer">静默登录</div>-->
</template>


<script>
// udid = getUUID()  authToken: getToken()  teacherId
import {login, silentLogin} from "@/api/login";
import {getAllUrlParam} from "@/utils/service";
import {setUUID} from "@/utils/local";

export default {
  name: "transfer",
  data(){
    return {
      urlObj: {}
    }
  },
  mounted() {
    this.getUrlInfo()
  },
  methods: {
    // 解析url
    getUrlInfo(){
      const urlObj = getAllUrlParam()
      if (urlObj.loginUdid){
        setUUID(urlObj.loginUdid)
      }
      if (urlObj.loginAuthToken){
        sessionStorage.setItem('sessionId', urlObj.loginAuthToken)
      }
      this.urlObj = urlObj
      this.getLogin()
    },
    // 去登录
    getLogin(){
      silentLogin(this.urlObj?.teacherId).then(res => {
        if (res.state === '11') {
          const now = new Date()
          const nowTime = now.getTime()
          const expireTime = nowTime + 604800000
          localStorage.setItem('expireTime', expireTime)
          this.toSave(res)
        }
      })
    },
    // 去保存
    toSave(res) {
      this.$store.commit('clearInclude')

      // 如果ai英语访问，隐藏头部
      const urlObj = this.urlObj
      if (urlObj.from === 'ai-client'){
        sessionStorage.setItem('hiddenGlobalNav', true)
        sessionStorage.setItem('loginFrom', urlObj.from)
      }

      const {data} = res
      data.gradeTypeId = data.section
      data.teacherId = data.id
      data.teacherName = data.name
      data.teacherHead = data.headPortrait
      data.teacherStatus = data.approvalStatus
      data.sessionId = data.authToken
      data.superAdmin = data.roleType
      this.$store.commit('setUserInfo', res.data)
      sessionStorage.setItem('teacherStatus', data.teacherStatus)
      sessionStorage.setItem('superAdmin', data.superAdmin)
      sessionStorage.setItem('sessionId', data.sessionId)
      sessionStorage.setItem('yearSystem', data.yearSystem)
      sessionStorage.setItem('schoolSection', data.schoolSection)
      sessionStorage.setItem('teacherId', data.teacherId)
      sessionStorage.setItem('teacherName', data.name)
      sessionStorage.setItem('schoolName', data.schoolName)
      sessionStorage.setItem('teacherHead', data.teacherHead)
      sessionStorage.setItem('gradeTypeId', data.gradeTypeId)
      sessionStorage.setItem('provinceId', data.provinceId)
      sessionStorage.setItem('cityId', data.cityId)
      sessionStorage.setItem('schoolId', data.schoolId)
      sessionStorage.setItem('phone', data.phone)
      this.getGoJump()
    },
    // 去跳转
    getGoJump(){
      this.$store.commit('routerClear')
      const urlObj = this.urlObj
      if (urlObj.redirect){
        window.location.replace(urlObj.redirect)
      }else{
        this.$router.replace({name: 'home'})
      }
    }
  }
}
</script>

<style scoped>

</style>
